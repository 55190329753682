import BaseController from '../base_controller'

export default class extends BaseController {
  static values = { 
    paymentPrice: Number,
    initialAssignments: Object, 
    debtDetails: Object,
    totalAppliedDeductions: Object,
    deductionsDetails: Object,
    localeCode: String,
    currencyCode: String,
    paymentId: Number }

  connect() {
    // Detect event before page reload or close
    document.addEventListener('beforeunload', this.handleBeforeUnload())

    this.setSelectedDebts()
    this.handleEnablingCheckboxes()
    this.setDebtPriceFields()
    this.setSelectedDeductions()
    this.enableSaveButton()
    this.showForm()
  }

  setInitialAssignments(){
    Object.entries(this.initialAssignmentsValue).forEach(([key, value]) => {
      this.saveValue(`amount_to_paid_debt_id_${key}`, value)
      let currencyNumberInput = document.getElementById(`amount_to_paid_debt_id_${key}`)
      
      if (currencyNumberInput) {
        let currencyStringInput = document.getElementById(`amount_to_paid_debt_id_${key}_masked`)
        let checkbox = document.getElementById(`debt_id_${key}`)
        currencyNumberInput.value = value
        currencyStringInput.value = value

        this.selectAndFillRow(currencyNumberInput, currencyStringInput, checkbox, value)
      }
    })
  }

  setSelectedDebts() {
    const sessionData = this.sessionStorageHash()

    Object.entries(sessionData).forEach(([key, value]) => {
      let currencyNumberInput = document.getElementById(key)

      if (currencyNumberInput) {
        let currencyStringInput = document.getElementById(`${key}_masked`)
        let debt_id = key.split("amount_to_paid_debt_id_")[1]
        let checkbox = document.getElementById(`debt_id_${debt_id}`)

        this.selectAndFillRow(currencyNumberInput, currencyStringInput, checkbox, value)
        this.showSubtableDeductions(debt_id)
      }
    });
  }

  selectAndFillRow(currencyNumberInput, currencyStringInput, checkbox, value) {
    currencyNumberInput.value = value
    currencyStringInput.value = value
    currencyStringInput.removeAttribute('disabled')
    checkbox.checked = true
  }

  validateInput(event) {
    setTimeout(() => {
      this.validateValueOfSelectedDebts(event)
      this.enableSaveButton()
      this.handleEnablingCheckboxes()
    }, 0)
  }

  validateValueOfSelectedDebts(event) {
    const sessionData = this.sessionStorageHash()
    let debt_id = event.target.id.split("amount_to_paid_debt_id_")[1]

    // Sum all the values from SessionStorage, excluding the value of the debt that will be edited.
    let totalAssigned = Object.entries(sessionData)
      .filter(([key]) => key !== event.target.id)
      .reduce((total, [, value]) => total + value, 0)

    if (totalAssigned + Number(event.target.value) <= this.paymentPriceValue && Number(event.target.value) <= this.getDebtBalance(debt_id)){
      this.hide_warning_debt(debt_id)
      this.saveValue(event.target.id, event.target.value)
    } else {
      let currencyStringInput = document.getElementById(`${event.target.id}_masked`)
      let currencyNumberInput = document.getElementById(event.target.id)

      this.restoreToZero(currencyStringInput, currencyNumberInput)
      this.show_warning_debt(debt_id)
    }
  }

  getDebtBalance(debt_id) {
    const total_applied_deduction = this.getAppliedDeductionsValueByDebt(debt_id)
    if (this.initialAssignmentsValue[debt_id]) {
      return Number(this.debtDetailsValue[debt_id]['assigned_plus_pending']) - total_applied_deduction
    } else {
      return Number(this.debtDetailsValue[debt_id]['money_balance']) - total_applied_deduction
    }
  }

  async sendForm() {
    let save_btn = document.getElementById('save-btn')
    save_btn.setAttribute('disabled', true)
    save_btn.removeAttribute('data-action')

    const sessionData = this.sessionStorageHash()
    const deductionsSessionStorage = this.deductionsSessionStorageHash()
    const patchUrl = this.translateRoute('edit_payment_assignments_payment', { id: this.paymentIdValue })
    sessionData['id'] = this.paymentIdValue;
    
    const debtsAndDeductionsSessionData = Object.assign({}, sessionData, deductionsSessionStorage);

    await fetch(patchUrl, {
      method: 'PATCH',
      headers:  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify(debtsAndDeductionsSessionData),
      redirect: 'manual'
    })
    .then(response => { return response.json() })
    .then(data => {
      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      } else {
        console.log('response received:', data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  // Enabled or disabled the input for the amount to be paid on the debt
  handleDebtSelection(event) {
    let checked = event.target.checked
    const string_debt_id = event.target.id
    const currencyInput = document.getElementById(`amount_to_paid_${string_debt_id}_masked`)
    let debt_id = string_debt_id.split('debt_id_')[1]

    if (checked){
      currencyInput.removeAttribute('disabled')
      this.autoCompleteCurrencyInput(debt_id)
      this.showSubtableDeductions(debt_id)
    }else{
      let currencyStringInput = document.getElementById(`amount_to_paid_${string_debt_id}_masked`)
      let currencyNumberInput = document.getElementById(`amount_to_paid_${string_debt_id}`)

      this.restoreToZero(currencyStringInput, currencyNumberInput)
      this.hide_warning_debt(debt_id)
      currencyInput.setAttribute('disabled', true)
      this.hideSubtableDeductions(debt_id)
      this.removeSelectedDeductions(debt_id)
      this.removeOlderDeductions(debt_id)
      this.restoreBalanceElement(debt_id)
    }

    this.enableSaveButton()
    this.handleEnablingCheckboxes()
  }

  saveValue(id, value) {
    sessionStorage.setItem(id, value)
  }

  // Return to zero debt when this is deselected
  restoreToZero(currencyStringInput, currencyNumberInput) {  
    currencyNumberInput.value = 0
    currencyStringInput.value = '$ 0'
    sessionStorage.removeItem(currencyNumberInput.id)
  }

  autoCompleteCurrencyInput(debt_id) {
    const sessionData = this.sessionStorageHash()
    let totalAssigned = Object.values(sessionData).reduce((total, value) => total + value, 0);
    let valueAvailableToPay = this.paymentPriceValue - totalAssigned
    let moneyBalanceDebt = 0

    if ( valueAvailableToPay != 0 ) {
      if (this.initialAssignmentsValue[debt_id]) {
        moneyBalanceDebt = Number(this.debtDetailsValue[debt_id]['assigned_plus_pending']) || 0
      } else {
        moneyBalanceDebt = Number(this.debtDetailsValue[debt_id]?.['money_balance']) || 0
      }

      let minValue = Math.min(...[valueAvailableToPay, moneyBalanceDebt])
      minValue = Math.round(minValue * 100) / 100

      if (minValue > 0) {
        this.addValueToAmountToPaidElement(debt_id, minValue)
      }
    }
  }

  addValueToAmountToPaidElement(debt_id, value) {
    let currencyStringInput = document.getElementById(`amount_to_paid_debt_id_${debt_id}_masked`)
    let currencyNumberInput = document.getElementById(`amount_to_paid_debt_id_${debt_id}`)

    if (value > 0) {
      this.saveValue(`amount_to_paid_debt_id_${debt_id}`, value)
    } else {
      sessionStorage.removeItem(`amount_to_paid_debt_id_${debt_id}`)
    }

    currencyNumberInput.value = value
    currencyStringInput.value = this.currencyString(value)
  }

  hide_warning_debt(debt_id) {
    let currencyStringInput = document.getElementById(`amount_to_paid_debt_id_${debt_id}_masked`)
    let warning = document.getElementById(`warning_debt_id_${debt_id}`)
    warning.style.display = 'none'
    currencyStringInput.classList.remove('border-red')
  }

  show_warning_debt(debt_id) {
    let currencyStringInput = document.getElementById(`amount_to_paid_debt_id_${debt_id}_masked`)
    let warning = document.getElementById(`warning_debt_id_${debt_id}`)
    warning.style.removeProperty('display')
    currencyStringInput.classList.add('border-red')
  }

  // get debt data from sesionStorage
  sessionStorageHash(){
    const sessionData = {};

    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
  
      if (key.includes('amount_to_paid_debt_id_')) {
        sessionData[key] = Number(sessionStorage.getItem(key));
      }
    }

    return sessionData
  }

  // format currency
  currencyString(number, minDecimal = 0, maxDecimal = 0) {
    this.formatter = new Intl.NumberFormat(this.localeCodeValue, { style: 'currency', currency: this.currencyCodeValue, minimumFractionDigits: minDecimal, maximumFractionDigits: maxDecimal })
    this.fraction = new Intl.NumberFormat(this.localeCodeValue, { style: 'currency', currency: this.currencyCodeValue })

    let formattedValue = ''
    if (number % 1 == 0){
      formattedValue = this.formatter.format(number)
    } else {
      formattedValue = this.fraction.format(number)
    }

    return formattedValue.replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ')
  }

  enableSaveButton() {
    let save_btn = document.getElementById('save-btn')
    const sessionData = this.sessionStorageHash()
    const deductionsSessionStorage = this.deductionsSessionStorageHash()
    const initialAssignmentsHash = Object.fromEntries(
      Object.entries( this.initialAssignmentsValue).map(([key, value]) => [
        `amount_to_paid_debt_id_${key}`, Number(value)
      ])
    )

    if (!this.areObjectsEqual(sessionData, initialAssignmentsHash) || !this.isObjectEmpty(deductionsSessionStorage)) {
      let action = 'click->payment--debt-reassignment#sendForm'
      save_btn.removeAttribute('disabled')
      save_btn.setAttribute('data-action', action)
    } else {
      save_btn.setAttribute('disabled', true)
      save_btn.removeAttribute('data-action')
    }
  }

  areObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    return keys1.every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
  }

  isObjectEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  handleEnablingCheckboxes() {
    const sessionData = this.sessionStorageHash()
    const totalAssigned = Object.values(sessionData).reduce((acc, value) => acc + value, 0)

    if (totalAssigned < this.paymentPriceValue){
      this.enableCheckboxes()
    } else {
      this.disableCheckboxes()
    }
  }

  disableCheckboxes() {
    const uncheckedCheckboxes = document.querySelectorAll("input[type='checkbox'][id*='debt_id_']:not(:checked)")
    uncheckedCheckboxes.forEach(checkbox => {
      checkbox.disabled = true;
    })
  }

  enableCheckboxes() {
    const uncheckedCheckboxes = document.querySelectorAll("input[type='checkbox'][id*='debt_id_']:not(:checked)")
    uncheckedCheckboxes.forEach(checkbox => {
      checkbox.disabled = false;
    })
  }

  // Deductions logic

  setDebtPriceFields() {
    const priceElementsWithoutDeductions = document.querySelectorAll('[id*="total_debt_without_deductions_id_"]')
    const priceElementsWithDeductions = document.querySelectorAll('[id*="total_debt_with_deductions_id_"]')

    priceElementsWithoutDeductions.forEach((element) => {
      const debtId = element.getAttribute('debt_id');

      if (this.totalAppliedDeductionsValue[debtId] === undefined) {
        element.style.display = 'none'
      }
    })

    const deductionsSessionStorage =  this.deductionsSessionStorageHash()

    Object.entries(deductionsSessionStorage).forEach(([key, value]) => {
      const match = key.match(/^deduction_id_(\d+)$/)
      if (match) {
        const deductionId = match[1]
        const debtId = this.deductionsDetailsValue[deductionId]['debt_id']
        const deductionPrice = this.deductionsDetailsValue[deductionId]['deduction_price']

        const priceElementsWithDeduction = document.getElementById(`total_debt_with_deductions_id_${debtId}`)        
        if (priceElementsWithDeduction) {
          const priceElementsWithoutDeduction = document.getElementById(`total_debt_without_deductions_id_${debtId}`) 

          priceElementsWithoutDeduction.removeAttribute('style')

          let new_price = Number(priceElementsWithDeduction.getAttribute('value')) - deductionPrice
          priceElementsWithDeduction.setAttribute('value', new_price)
          priceElementsWithDeduction.innerHTML = this.currencyString(new_price, 2, 2)

          this.addDeductionToDebtBalanceElement(debtId, deductionId)
        }
      }
    })
  }

  setSelectedDeductions() {
    const deductionsSessionStorage = this.deductionsSessionStorageHash()

    Object.keys(deductionsSessionStorage).forEach((key) => {
      let deductionCheckbox = document.getElementById(key)
      if (deductionCheckbox) {
        deductionCheckbox.checked = true
      }
    })
  }

  // get deductions data from sesionStorage
  deductionsSessionStorageHash(){
    const sessionData = {};

    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
  
      if (key.includes('deduction_id_')) {
        sessionData[key] = sessionStorage.getItem(key);
      }
    }

    return sessionData
  }

  handleDeductionSelection(event) {
    let checked = event.target.checked
    const string_deduction_id = event.target.id
    const debt_id = event.target.getAttribute('debt_id')
    const deduction_id = event.target.getAttribute('deduction_id')

    if (checked){
      this.saveValue(string_deduction_id, true)
      this.showDebtPriceElementWithDeduction(debt_id, deduction_id)
      this.addDeductionToDebtBalanceElement(debt_id, deduction_id)
      this.addDeductionToAmountToPaidElement(debt_id, deduction_id)
    }else{
      sessionStorage.removeItem(string_deduction_id)
      this.hideDebtPriceElementWithDeduction(debt_id, deduction_id)
      this.removeDeductionToDebtBalanceElement(debt_id, deduction_id)
      this.removeDeductionToAmountToPaidElement(debt_id, deduction_id)
    }

    this.enableSaveButton()    
  }
  
  removeSelectedDeductions(debt_id) {
    const selectedDeductions = document.querySelectorAll(
      `[id*='deduction_id_']:not([disabled])[debt_id='${debt_id}']:checked`
    )
    
    selectedDeductions.forEach(element => {
      let string_deduction_id = element.id
      let deduction_id = element.getAttribute('deduction_id')
      element.checked = false
      sessionStorage.removeItem(string_deduction_id)
      this.hideDebtPriceElementWithDeduction(debt_id, deduction_id)
    })
  }

  removeOlderDeductions(debt_id) {
    const selectedDeductions = document.querySelectorAll(
      `[id*='deduction_id_']:disabled[debt_id='${debt_id}']`
    )
    let sum = 0
    selectedDeductions.forEach(element => {
      let deduction_id = element.getAttribute('deduction_id')
      let deductionPrice = Number(this.deductionsDetailsValue[deduction_id]['deduction_price'])
      sum += deductionPrice
    })

    let deleted_deductions_amount = 0
    if (Number(this.debtDetailsValue[debt_id]['assigned_plus_pending']) - Number(this.debtDetailsValue[debt_id]['money_balance']) == Number(this.debtDetailsValue[debt_id]['money_paid']) - sum){
      selectedDeductions.forEach(element => {
        let deduction_id = element.getAttribute('deduction_id')
        element.checked = false
        element.disabled = false
        this.hideDebtPriceElementWithDeduction(debt_id, deduction_id)
        deleted_deductions_amount += Number(this.deductionsDetailsValue[deduction_id]['deduction_price'])
      })
    }

    if (deleted_deductions_amount == 0){
      return
    }

    let money_balance = Number(this.debtDetailsValue[debt_id]['money_balance'])
    let new_money_balance = (money_balance + deleted_deductions_amount).toFixed(1)
    this.updateDebtDetails(debt_id, new_money_balance, 'money_balance')

    let assigned_plus_pending = Number(this.debtDetailsValue[debt_id]['assigned_plus_pending'])
    let new_assigned_plus_pending = (assigned_plus_pending + deleted_deductions_amount).toFixed(1)
    this.updateDebtDetails(debt_id, new_assigned_plus_pending, 'assigned_plus_pending')

    let money_paid = Number(this.debtDetailsValue[debt_id]['money_paid'])
    let new_money_paid = (money_paid - deleted_deductions_amount).toFixed(1)
    this.updateDebtDetails(debt_id, new_money_paid, 'money_paid')
  }

  updateDebtDetails(debt_id, new_value, value_name) {
    const updatedDetails = { ...this.debtDetailsValue };

    if (updatedDetails[debt_id]) {
      updatedDetails[debt_id][value_name] = new_value;
    }

    this.debtDetailsValue = updatedDetails;
  }

  showDebtPriceElementWithDeduction(debt_id, deduction_id) {
    const priceElementWithoutDeduction = document.getElementById(`total_debt_without_deductions_id_${debt_id}`)
    const priceElementWithDeduction = document.getElementById(`total_debt_with_deductions_id_${debt_id}`)

    priceElementWithoutDeduction.removeAttribute('style')

    let deductionPrice = Number(this.deductionsDetailsValue[deduction_id]['deduction_price'])
    let new_price = Number(priceElementWithDeduction.getAttribute('value')) - deductionPrice
    priceElementWithDeduction.setAttribute('value', new_price)
    priceElementWithDeduction.innerHTML = this.currencyString(new_price, 2, 2)
  }

  hideDebtPriceElementWithDeduction(debt_id, deduction_id) {
    const priceElementWithoutDeduction = document.getElementById(`total_debt_without_deductions_id_${debt_id}`)
    const priceElementWithDeduction = document.getElementById(`total_debt_with_deductions_id_${debt_id}`)

    let deductionPrice = Number(this.deductionsDetailsValue[deduction_id]['deduction_price'])
    let new_price = Number(priceElementWithDeduction.getAttribute('value')) + deductionPrice
    priceElementWithDeduction.setAttribute('value', new_price)
    priceElementWithDeduction.innerHTML = this.currencyString(new_price, 2, 2)

    if (new_price == Number(this.debtDetailsValue[debt_id]['price'])){
      priceElementWithoutDeduction.style.display = 'none'
    }
  }

  showSubtableDeductions(debt_id) {
    const table = document.getElementById(`deductions_table_debt_id_${debt_id}`)
    if (table === null) { return }
    table.removeAttribute('style')
  }

  hideSubtableDeductions(debt_id) {
    const table = document.getElementById(`deductions_table_debt_id_${debt_id}`)
    if (table === null) { return }
    table.style.display = 'none'
  }

  // Balance element
  addDeductionToDebtBalanceElement(debt_id, deduction_id) {
    const balanceElement = document.getElementById(`balance_debt_id_${debt_id}`)
    let deductionPrice = Number(this.deductionsDetailsValue[deduction_id]['deduction_price'])
    let newPrice = Number(balanceElement.getAttribute('value')) - deductionPrice
    
    if (Number(this.debtDetailsValue[debt_id]['money_balance']) == 0) {
      return
    }
    
    if (newPrice >= 0) {
      balanceElement.setAttribute('value', newPrice)
      balanceElement.innerHTML = this.currencyString(newPrice, 2, 2)
    } else {
      balanceElement.setAttribute('value', newPrice)
      balanceElement.innerHTML = this.currencyString(0, 2, 2)
    }
  }

  removeDeductionToDebtBalanceElement(debt_id, deduction_id) {
    const balanceElement = document.getElementById(`balance_debt_id_${debt_id}`)
    let deductionPrice = Number(this.deductionsDetailsValue[deduction_id]['deduction_price'])
    let newPrice = Number(balanceElement.getAttribute('value')) + deductionPrice
  
    if (Number(this.debtDetailsValue[debt_id]['money_balance']) == 0) {
      return
    }

    if (newPrice >= 0) {
      balanceElement.setAttribute('value', newPrice)
      balanceElement.innerHTML = this.currencyString(newPrice, 2, 2)
    } else {
      balanceElement.setAttribute('value', newPrice)
      balanceElement.innerHTML = this.currencyString(0, 2, 2)
    }
  }

  restoreBalanceElement(debt_id) {
    const balanceElement = document.getElementById(`balance_debt_id_${debt_id}`)
    const price = Number(this.debtDetailsValue[debt_id]['money_balance'])
    balanceElement.setAttribute('value', price)
    balanceElement.innerHTML = this.currencyString(price, 2, 2)
  }

  // AmountToPaid Element
  addDeductionToAmountToPaidElement(debt_id, deduction_id) {
    const amountToPaidValue = sessionStorage.getItem(`amount_to_paid_debt_id_${debt_id}`)

    if (amountToPaidValue) {
      let deductionPrice = Number(this.deductionsDetailsValue[deduction_id]['deduction_price'])
      let newPrice = Number(amountToPaidValue) - deductionPrice
    
      if (newPrice >= 0) {
        this.addValueToAmountToPaidElement(debt_id, newPrice)
      } else {
        this.addValueToAmountToPaidElement(debt_id, 0)
      }
    }
  }

  removeDeductionToAmountToPaidElement(debt_id, deduction_id) {
    const sessionData = this.sessionStorageHash()
    const amountToPaidValue = sessionStorage.getItem(`amount_to_paid_debt_id_${debt_id}`)
    
    // Sum all the values from SessionStorage, excluding the value of the debt that will be edited.
    let totalAssigned = Object.entries(sessionData)
      .filter(([key]) => key !== `amount_to_paid_debt_id_${debt_id}`)
      .reduce((total, [, value]) => total + value, 0)

    if (amountToPaidValue) {
      let deductionPrice = Number(this.deductionsDetailsValue[deduction_id]['deduction_price'])
      let newPrice = Number(amountToPaidValue) + deductionPrice
    
      if (totalAssigned + newPrice <=  this.paymentPriceValue && newPrice <= this.getDebtBalance(debt_id)) {
        this.addValueToAmountToPaidElement(debt_id, newPrice)
      } else {
        this.addValueToAmountToPaidElement(debt_id, 0)
      }
    }
  }


  getAppliedDeductionsValueByDebt(debt_id) {
    let total = 0
    const deductionsSessionStorage =  this.deductionsSessionStorageHash()

    Object.entries(deductionsSessionStorage).forEach(([key, value]) => {
      const match = key.match(/^deduction_id_(\d+)$/)
      if (match) {
        const deductionId = match[1]
        const deductionDebtId = this.deductionsDetailsValue[deductionId]['debt_id']
        if (deductionDebtId == debt_id) {
          let deductionPrice = Number(this.deductionsDetailsValue[deductionId]['deduction_price'])
          total += deductionPrice
        }
      }
    })

    return total
  }

  // general
  showForm() {
    document.getElementById('all_assignments_form').removeAttribute('style')
    document.getElementById('loader_element').style.display = 'none'
  }

  // Functions to handle sessionStorage cleanup

  markPagyClick(){
    sessionStorage.setItem('pagy_navigation', true)
  }

  handleBeforeUnload() {
    const isPagyNavigation = sessionStorage.getItem("pagy_navigation");

    if (isPagyNavigation) {
      sessionStorage.removeItem("pagy_navigation");
    } else {
      sessionStorage.clear();
      this.setInitialAssignments()
    }
  }
}
