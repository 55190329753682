import { Controller } from 'stimulus'
import I18n from 'i18n-js'

const DEFAULT_FISCAL_VALUES = {
  cfdi_use: 'S01',
  fiscal_regime: '616',
  general_public_name: 'PUBLICO EN GENERAL',
  general_public_rfc: 'XAXX010101000',
  postal_code: '06700'
}

export default class extends Controller {
  static targets = ['countrySelector', 'defaultFiscalValues', 'defaultFiscalValuesCheckbox', 'fiscalIdentificationSection',
    'communeDiv', 'accountRut', 'accountBusinessName', 'fiscalName', 'fiscalPostalCode', 'fiscalRegime', 'fiscalCfdiUse']

  displayCheckbox(event) {
    let value = event.currentTarget.value
    if (value == 'MX') {
      I18n.locale = 'es-MX'
      this.defaultFiscalValuesCheckboxTarget.classList.remove('hidden')
      this.fiscalIdentificationSectionTarget.classList.remove('hidden')
      if (this.hasCommuneDivTarget) this.communeDivTarget.classList.add('hidden')
    } else {
      switch (value) {
        case 'CL':
        case 'default_category':
          I18n.locale = 'es'
          break
        case 'US':
          I18n.locale = 'en'
          break
        default:
          I18n.locale = `es-${value}`
      }
      this.defaultFiscalValuesCheckboxTarget.classList.add('hidden')
      this.fiscalIdentificationSectionTarget.classList.add('hidden')
      if (this.hasCommuneDivTarget) this.communeDivTarget.classList.remove('hidden')
    }

    this.accountRutTarget.labels[0].innerHTML = I18n.t('activerecord.attributes.account.rut')
    this.accountRutTarget.placeholder = I18n.t('placeholders.account.rut')
  }

  setDefaultValues(event) {
    let checked = event.currentTarget.checked

    if (checked) {
      if (this.hasAccountBusinessNameTarget) this.accountBusinessNameTarget.value = DEFAULT_FISCAL_VALUES.general_public_name
      if (this.hasFiscalNameTarget) this.fiscalNameTarget.value = DEFAULT_FISCAL_VALUES.general_public_name
      if (this.hasAccountRutTarget) this.accountRutTarget.value = DEFAULT_FISCAL_VALUES.general_public_rfc
      if (this.hasFiscalPostalCodeTarget) this.fiscalPostalCodeTarget.value = DEFAULT_FISCAL_VALUES.postal_code
      if (this.hasFiscalRegimeTarget) {
        this.fiscalRegimeTarget.value = DEFAULT_FISCAL_VALUES.fiscal_regime
        this.fiscalRegimeTarget.dispatchEvent(new Event('change'))
      }
      if (this.hasFiscalCfdiUseTarget) this.fiscalCfdiUseTarget.value = DEFAULT_FISCAL_VALUES.cfdi_use
    } else {
      if (this.hasFiscalCfdiUseTarget) this.fiscalCfdiUseTarget.value = 0
      if (this.hasFiscalRegimeTarget) {
        this.fiscalRegimeTarget.value = ''
        this.fiscalRegimeTarget.dispatchEvent(new Event('change'))
      }
      if (this.hasFiscalPostalCodeTarget) this.fiscalPostalCodeTarget.value = ''
      if (this.hasAccountRutTarget) this.accountRutTarget.value = ''
      if (this.hasFiscalNameTarget) this.fiscalNameTarget.value = ''
      if (this.hasAccountBusinessNameTarget) this.accountBusinessNameTarget.value = ''
    }
  }
}
