import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    manualAssignment: Boolean
  }

  connect() {

  }

  getPropertyId(event) {
    const target = event.currentTarget
    const bill_id = target.getAttribute('bill');
    const month = target.getAttribute('month');
    const year = target.getAttribute('year');
    const loader = document.getElementById(`loader_${bill_id}`);
    const container = document.getElementById(`property-index-container-${bill_id}`);

    if (!container) {
      loader.removeAttribute('style');
  
      $.ajax({
        url: window.property_index_path,
        data: { bill_id, month, year },
        type: 'get',
        success: (data) => {
          loader.style.display = 'none';
          this.getDebtsForPayment(target);
        }
      })
    }
  }

  getDebtsForPayment(target, date = "") {
    if (!this.manualAssignmentValue) { return }

    const property_id = target.getAttribute('property_id');

    document.querySelectorAll(`.prop${property_id}`).forEach((el) => el.classList.remove('max-price'));
    document.querySelectorAll(`.prop${property_id}:not(.superDisabled)`).forEach((el) => el.removeAttribute('disabled'));
    document.querySelector(`.manual_assignation${property_id} .loader-content`).style.display = 'block';

    const data = new URLSearchParams({ 'property[id]': property_id, date });
    const url = `${window.debt_assignation_path}?${data}`;
    const headers = { contentType: 'text/html',
                      Accept: 'text/html',
                      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') };

    fetch(url, { method: 'GET', headers: headers })
    .then((response) => response.text())
    .then((data) => {
      document.querySelector(`.manual_assignation${property_id} .debt-content`).innerHTML = data;
      document.querySelector(`.manual_assignation${property_id} .loader-content`).style.display = 'none';
    });
  }

  showPaymentsButtonProperty(event) {
    document.querySelectorAll('[id*=show_payments_button_property]').forEach((el) => el.style.display = 'inline');
    event.currentTarget.style.display = 'none';
    const property_id = event.currentTarget.getAttribute('property_id');
    document.querySelectorAll('[id*=hide_payments_button_property]').forEach((el) => el.style.display = 'none');
    document.getElementById(`hide_payments_button_property_${property_id}`).style.display = 'inline';
  }

  hideDebtsButtonPayment(event) {
    event.currentTarget.style.display = 'none';
    const property_id = event.currentTarget.getAttribute('property_id');
    document.getElementById(`show_payments_button_property_${property_id}`).style.display = 'inline';
  }

  cancelButtonInlinePayment(event) {
    const property_id = event.currentTarget.getAttribute('property_id');
    document.getElementById(`hide_payments_button_property_${property_id}`).style.display = 'none';
    document.getElementById(`show_payments_button_property_${property_id}`).style.display = 'inline';
  }
}
