import BaseController from './base_controller';
import I18n from 'i18n-js';

export default class extends BaseController {

  filterPendings(e) {
    const url = new URL(window.location.href);

    if (e.currentTarget.checked) {
      url.searchParams.set('show_pending', '1');
    } else {
      url.searchParams.delete('show_pending');
    }

    window.location.href = url.toString();
  }

  toggleStatus(event) {
    const checkbox = event.currentTarget;    
    const url = checkbox.dataset.url;
    const propertyUserId = checkbox.id.split('_').pop(); // Extrae el ID del checkbox
    const statusText = document.getElementById(`status_text_${propertyUserId}`);
    if (statusText) {
      statusText.textContent = "Habilitado";
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
    })
    .then(response => response.json())
    .then(data => {
      triggerNotice(I18n.t('views.property_users.notices.successful_enablement', { property_user_name: data.property_user_name }));
      Turbo.visit(window.location.href, { action: "replace" });
    })
  }
}
