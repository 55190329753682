import { Controller } from 'stimulus';
import I18n from 'i18n-js';

export default class extends Controller {
  static targets = ['unreadTab', 'readTab', 'unreadContent', 'readContent', 'markAllAsRead'];

  connect() {
    this.hasTracked = false;
  }

  showUnread() {
    this.toggleTab(this.unreadTabTarget, this.readTabTarget);
    this.toggleContent(this.unreadContentTarget, this.readContentTarget);
    this.markAllAsReadTarget.style.display = 'block';
  }

  showRead() {
    this.toggleTab(this.readTabTarget, this.unreadTabTarget);
    this.toggleContent(this.readContentTarget, this.unreadContentTarget);
    this.markAllAsReadTarget.style.display = 'none';
  }

  preventDropdownClose(event) {
    event.stopPropagation();
  }

  notificationsTrack() {
    if (this.hasTracked) return;

    this.hasTracked = true;

    trackEvent('Notification', {
      action: I18n.t('mixpanel.notification.open'),
    });
  }

  toggleTab(activeTab, inactiveTab) {
    activeTab.classList.add('active');
    inactiveTab.classList.remove('active');
  }

  toggleContent(activeContent, inactiveContent) {
    activeContent.classList.add('active');
    inactiveContent.classList.remove('active');
  }
}

