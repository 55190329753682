import { Controller } from "@hotwired/stimulus";

let callback;

export default class extends Controller {
  static targets = ["modal", "cancelButton", "createButton", "spinner"];
  static values = {
    emptyFieldFlag: Boolean,
  };

  connect() {
    this.submitButtonAvailability();
  }

  closeModal() {
    $(this.modalTarget).modal("hide");
  }

  showModal() {
    $(this.modalTarget).modal();
  }

  submitButtonAvailability() {
    this.emptyFieldFlagValue = false;
    document.querySelector(
      "#dynamic_fields_modal .error-container"
    ).textContent = "";
    document
      .querySelectorAll(`[id^="dynamic_fields_modal_field_"]`)
      .forEach((f) => {
        if (f.value.trim() == "" && !this.emptyFieldFlagValue) {
          this.emptyFieldFlagValue = true;
          return;
        }
      });
    if (this.emptyFieldFlagValue) {
      document
        .getElementById("dynamic_fields_modal_primary_button")
        .setAttribute("disabled", "");
    } else {
      document
        .getElementById("dynamic_fields_modal_primary_button")
        .removeAttribute("disabled");
    }
  }

  showDynamicFieldsDialogModal(acceptCallback, cancelCallback = null) {
    $("#dynamic_fields_modal_secondary_button").click(function () {
      if (cancelCallback) cancelCallback();
    });
    callback = acceptCallback;
    this.showModal();
  }

  handleOnClickCreateButton(e) {
    if (!this.createButtonTarget.hasAttribute("disabled")) {
      e.preventDefault();
      e.stopPropagation();
      this.createButtonTarget.setAttribute("disabled", "");
      if (!this.emptyFieldFlagValue) {
        this.spinnerTarget.classList.remove("hide");
        this.handleAcceptCallback();
      }
    }
  }

  handleAcceptCallback() {
    if (callback.isAjaxRequest) {
      this.executeAjaxRequest();
    } else {
      callback.callbackContent();
      document
        .querySelector("#dynamic_fields_modal .spinner")
        .classList.add("hide");
    }
  }

  requestDataFill(_content) {
    let fields = {};
    document
      .querySelectorAll(`[id^="dynamic_fields_modal_field_"]`)
      .forEach((field) => {
        fields[String(field.name)] = field.value;
      });
    return fields;
  }

  executeAjaxRequest() {
    const controllerScope = this;
    $.ajax({
      url: callback.request.path,
      method: callback.request.method,
      data: controllerScope.requestDataFill(callback.request),
      dataType: "json",
      error: function (response) {
        $("#dynamic_fields_modal .error-container").html(
          response.responseJSON.errors
        );
        document
          .querySelector("#dynamic_fields_modal .spinner")
          .classList.add("hide");
        if (callback.errorCallback) callback.errorCallback();
      },
      success: function (data) {
        document
          .querySelector("#dynamic_fields_modal .spinner")
          .classList.add("hide");
        callback.callbackContent(
          data,
          controllerScope.closeModal.bind(controllerScope)
        );
      },
    });
  }
}
