import DynamicFieldsDialog from "./dynamic_fields_dialog_controller";

export default class extends DynamicFieldsDialog {
  static values = {
    newCategoryText: String,
  };
  static targets = [
    "serviceBillingCategories",
    "billingCustomCategory",
    "serviceBillingCategoriesSelection",
    "submitDisabledHelperMessage",
    "remunerationsSubmitButton",
  ];

  connect() {
    this.settingCategorySelector();
    this.handleDisableSubmitButton();
    super.connect();
  }

  isRemunerationsTabActive() {
    return this.remunerationEditTabTarget.classList.contains("active");
  }

  baseNewCategorySelected() {
    return (
      this.serviceBillingCategoriesTarget.selectedOptions[0].text ==
      this.newCategoryTextValue
    );
  }

  customNewCategorySelected() {
    return (
      this.serviceBillingCategoriesSelectionTarget.selectedOptions[0].text ==
      this.newCategoryTextValue
    );
  }

  settingCategorySelector() {
    if (
      this.serviceBillingCategoriesSelectionTarget.options.length > 1 &&
      this.baseNewCategorySelected()
    ) {
      this.billingCustomCategoryTarget.style.display = "block";
    }
  }

  handleRemunerationsCustomCategorySelector() {
    this.handleDisableSubmitButton();
    if (this.baseNewCategorySelected()) {
      this.billingCustomCategoryTarget.style.display = "block";
      this.handleRemunerationsNewCategoryField();
    } else {
      this.billingCustomCategoryTarget.style.display = "none";
    }
  }

  handleDisableSubmitButton() {
    if (this.customNewCategorySelected() && this.baseNewCategorySelected()) {
      this.submitDisabledHelperMessageTarget.style.display = "block";
      this.remunerationsSubmitButtonTarget.setAttribute("disabled", "");
    } else {
      this.submitDisabledHelperMessageTarget.style.display = "none";
      this.remunerationsSubmitButtonTarget.removeAttribute("disabled");
    }
  }

  handleNoCategoryScenario() {
    this.handleRemunerationsNewCategoryField();
  }

  updateOtherCategorySelect(response, closeDialog) {
    this.serviceBillingCategoriesSelectionTarget.selectedOptions[0].selected = false;
    this.serviceBillingCategoriesSelectionTarget.add(
      new Option(response.category.name, response.category.id, true, true)
    );
    this.handleDisableSubmitButton();
    closeDialog();
  }

  handleRemunerationsNewCategoryField() {
    this.handleDisableSubmitButton();
    if (this.customNewCategorySelected()) {
      const params = {
        isAjaxRequest: true,
        callbackContent: this.updateOtherCategorySelect.bind(this),
        callbackError: null,
        request: {
          path: `/categorias`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
        },
      };
      this.showDynamicFieldsDialogModal(
        params,
        this.handleDisableSubmitButton.bind(this)
      );
    }
  }
}
