import { Controller } from "stimulus"
import AutoNumeric from "autonumeric"
import I18n from "i18n-js";
export default class extends Controller {
  static targets = [ 'form' ]
  initialize() {
    this.submittingForm = false;
  }

  async call(event){
    if (this.submittingForm) {
      return false
    }

    let changed = event.currentTarget;
    let bonusFactor = event.currentTarget.dataset.bonusFactor;
    let identification = this.splitIndexId(changed)
    let readyToSubmit = this.changeResponse(identification[0], identification[1], changed, bonusFactor)

    if (readyToSubmit) {
      this.submittingForm = true;
      let response = await document.getElementById(`form-${identification[1]}-${identification[0]}`).requestSubmit();
      this.refreshModal()
      return response
    }
  }

  splitIndexId(changed) {
    let arr = changed.id.split('-');
    let index = arr.pop();
    if (index == 'value'){
      arr.pop();
      index = arr.pop();
    }
    let id = arr.pop();

    return [index, id]
  }

  changeResponse(index, id, changed, bonusFactor) {
    let bonusType = document.getElementById(`bonus-type-${id}-${index}-hidden-value`)
    let checked = document.getElementById(`checked-${id}-${index}`)
    let responsability_bonus_for_extra_hours = document.getElementById(`responsability_bonus_for_extra_hours-${id}-${index}`)
    let name = document.getElementById(`name-${id}-${index}`)
    if (changed == bonusType) {
      this.showNameField(changed, name, checked)
      this.changeCheckbox(changed, checked, responsability_bonus_for_extra_hours)
    }

    let valueBeforeTax = document.getElementById(`value-before-tax-${id}-${index}`)
    let valuePostTax = document.getElementById(`value-post-tax-${id}-${index}`)
    if (changed == valueBeforeTax || changed == valuePostTax) {
      let values = this.calculateBonusValue(changed, valueBeforeTax, valuePostTax, bonusFactor)
      document.getElementById(`value-${id}-${index}`).value = values[0]
      document.getElementById(`post-tax-${id}-${index}`).value = values[1]
    }

    let customizedComplete = bonusType.value == 'customized' && name.value != '' && valueBeforeTax.value != '' && valuePostTax.value != ''
    let regularComplete = bonusType.value != 'customized' && valueBeforeTax.value != '' && valuePostTax.value != ''
    return customizedComplete || regularComplete
  }

  showNameField(changed, name, checked) {
    if (changed.value == 'customized') {
      checked.checked = false;
      name.classList.remove('hidden')
      checked.disabled = false;
    } else {
      name.value = ''

      if (changed.value == 'responsability') checked.checked = true;
      if (changed.value == 'aguinaldo') checked.checked = false;
      if (changed.value != 'responsability') $(checked.parentElement).tooltip().attr('data-original-title', '');
      if (changed.value == 'responsability') $(checked.parentElement).tooltip().attr('data-original-title', I18n.t('views.remunerations.salary_payment_drafts.bonus.bonus_extra_hours'));

      checked.disabled = true;
      name.classList.add('hidden')
    }
  }

  changeCheckbox(changed, checked, responsability_bonus_for_extra_hours) {
    if (changed.value == 'responsability') {
      checked.classList.add('hidden')
      responsability_bonus_for_extra_hours.classList.remove('hidden')
    } else {
      checked.classList.remove('hidden')
      responsability_bonus_for_extra_hours.classList.add('hidden')
    }
  }

  calculateBonusValue(changed, valueBeforeTax, valuePostTax, bonusFactor) {
    let changedValue = AutoNumeric.getAutoNumericElement(changed).getNumber()
    let bonusValue = 0
    if (changedValue <= 0) return [null, null]

    if (changed == valueBeforeTax) {
      bonusValue = Math.round(changedValue * bonusFactor)
      AutoNumeric.getAutoNumericElement(valuePostTax).set(bonusValue)
      valuePostTax.classList.add('disabled')
      return [changedValue, false]
    }
    bonusValue = Math.round(changedValue / bonusFactor)
    AutoNumeric.getAutoNumericElement(valueBeforeTax).set(bonusValue)
    valueBeforeTax.classList.add('disabled')
    return [bonusValue, true]
  }

  enableSubmit(event) {
    let changed = event.currentTarget;
    let identification = this.splitIndexId(changed)
    let bonusType = document.getElementById(`bonus-type-${identification[1]}-${identification[0]}-hidden-value`)
    let name = document.getElementById(`name-${identification[1]}-${identification[0]}`)
    let valueBeforeTax = document.getElementById(`value-before-tax-${identification[1]}-${identification[0]}`)
    let valuePostTax = document.getElementById(`value-post-tax-${identification[1]}-${identification[0]}`)

    let customizedComplete = bonusType.value == 'customized' && name.value != '' && (valueBeforeTax.value != '' || valuePostTax.value != '')
    let regularComplete = bonusType.value != 'customized' && (valueBeforeTax.value != '' || valuePostTax.value != '')
    if (customizedComplete || regularComplete) {
      let disabledButton = document.getElementById('salary-payment-disabled-button')
      let enabledButton = document.getElementById('salary-payment-enabled-button')

      enabledButton.classList.remove('hidden')
      disabledButton.classList.add('hidden')}
  }

  async refreshModal() {
    await new Promise(resolve => setTimeout(resolve, 1000));

    var selectedMonth = document.getElementById('month').value;
    var selectedYear = document.getElementById('year').value;

    fetch(`/remuneraciones/libro_liquidaciones/refresh_modal/${selectedYear}/${selectedMonth}`, {
      method: 'GET',
      headers:  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    .then(response => response.json())
    .then(data  => {
      let massiveSalaryEmployees = document.getElementById('employees-count');
      let massiveSalaryWithoutWorkedDaysIds = document.getElementById('worked-days-ids');

      massiveSalaryEmployees.textContent = data.employees;
      if (data.has_part_time_employees_without_worked_days) {
        massiveSalaryWithoutWorkedDaysIds.classList.remove('hidden');
      }
      else{
        massiveSalaryWithoutWorkedDaysIds.classList.add('hidden');
      }
    })
  }
}
