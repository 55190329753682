import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['notFoundMessage', 'pagination', 'table', 'statusLabel'];
  static values = { perPage: Number, totalPages: Number }

  setStatus({ params, target }) {
    this.newStatus = params.status;
    this.statusLabelTarget.innerText = target.innerHTML;
  }

  applyFilter(event) {
    event.preventDefault();
    this.currentStatus = this.newStatus;
    this._changePage(1);
  }

  removeDocument({ params }) {
    this.documentToRemove = params.row;
    $('#removeDocumentModal').modal('show');
  }

  changeDocument({ params, target }) {
    let file = target.files[0];
    // Only PDF, GIF, JPEG or PNG files, and no larger than 5MB
    if (['application/pdf', 'image/gif', 'image/jpeg', 'image/png'].includes(file.type) && file.size <= 5 * 1024 * 1024) {
      let tableRow = this.tableTarget.querySelector('#tableRow' + params.row);
      tableRow.querySelector('.form-control').innerHTML = file.name;
      tableRow.querySelector('.btn-remove-document').classList.remove('hidden');
      tableRow.querySelector('input[type=hidden].remove-document').value = null;
    } else {
      target.files = null;
    }
  }

  // admin view of property users module
  adminSelectedDocument({ params, target }) {
    let file = target.files[0];
    if (['application/pdf', 'image/gif', 'image/jpeg', 'image/png'].includes(file.type) && file.size <= 5 * 1024 * 1024) {
      let propertyUserContainer = document.querySelector('.property_options_container.property_options_' + params.row);
      const submitBtn = propertyUserContainer.querySelector(".upload-document-submit-btn");
      const spanElement = submitBtn.querySelector('span');
      spanElement && (spanElement.textContent = params.uploadingText);
      submitBtn.querySelector('i')?.classList.remove('fa-upload');
      submitBtn.querySelector('i')?.classList.add('fa-spinner', 'fa-spin');
      submitBtn.classList.add('disabled');
      submitBtn.click();
    } else {
      alert('El documento debe ser una imagen o pdf y no debe pesar más de 5 MB');
      target.value = null;
    }
  }

  adminUploadDocument(event) {
    const inputElement = event.target.querySelector("input[name='user_documents[property_validations][ownership_document]']");
    if (inputElement.files.length == 0) {
      event.stopPropagation();
      event.preventDefault();
      inputElement.click();
    }
  }

  confirmDocumentRemoval() {
    let tableRow = this.tableTarget.querySelector('#tableRow' + this.documentToRemove);
    tableRow.querySelector('input[type=file]').files = null;
    tableRow.querySelector('input[type=hidden].remove-document').value = true;
    tableRow.querySelector('.form-control').innerHTML = null;
    tableRow.querySelector('.btn-remove-document').classList.add('hidden');
    this.documentToRemove = null;
  }

  changePage(event) {
    event.preventDefault();

    let page = parseInt(event.target.innerHTML);

    this._changePage(page);
  }

  nextPage(event) {
    event.preventDefault();

    let currentPage = parseInt(this.paginationTarget.querySelector('.active').children[0].innerHTML);

    if (currentPage < this._totalPages()) { this._changePage(currentPage + 1); }
  }

  previousPage(event) {
    event.preventDefault();

    let currentPage = parseInt(this.paginationTarget.querySelector('.active').children[0].innerHTML);

    if (currentPage > 1) { this._changePage(currentPage - 1); }
  }

  _changePage(page) {
    let max = page * this.perPageValue;
    let min = max - this.perPageValue;
    let index = 0;

    for (let tableRow of this.tableTarget.children) {
      let statusIsValid = typeof this.currentStatus == 'undefined' || tableRow.querySelector(`.label-status-${this.currentStatus}`);

      if (index >= min && index < max && statusIsValid) {
        tableRow.classList.remove('hidden');
      } else {
        tableRow.classList.add('hidden');
      }

      if (statusIsValid) {
        index++;
      }
    }

    if (this.paginationTarget) {
      let totalPages = this._totalPages();

      if (totalPages > 1) {
        this.paginationTarget.classList.remove('hidden');

        for (let element of this.paginationTarget.children) {
          let pageNumber = element.children[0].innerHTML;

          if (isNaN(pageNumber)) { continue; }

          if (pageNumber <= totalPages) {
            element.classList.remove('hidden');
          } else {
            element.classList.add('hidden');
          }

          if (page == pageNumber) {
            element.classList.add('active');
          } else {
            element.classList.remove('active');
          }
        }
      } else {
        this.paginationTarget.classList.add('hidden');
      }
    }

    if (this.tableTarget.querySelector('tr:not(.hidden)')) {
      this.notFoundMessageTarget.classList.add('hidden');
    } else {
      this.notFoundMessageTarget.classList.remove('hidden');
    }
  }

  _totalPages() {
      if (typeof this.currentStatus != 'undefined') {
        return Math.ceil(this.tableTarget.querySelectorAll(`.label-status-${this.currentStatus}`).length / this.perPageValue);
      } else {
        return this.totalPagesValue;
      }
  }
};
