import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'propertySelector', 'titleInput', 'priceInput', 'finedAtCalendar', 'submitBtn', 'feesSelector', 'chargeMethod',
    'chargeMethodSelector', 'month', 'year', 'interestsFields', 'interestCheckbox', 'interestCalendarField', 'amountInput',
    'selectedFine', 'unitPrice', 'descriptionInput'
  ]

  static values = { month: Number, year: Number, editMode: Boolean }

  calculatePrice() {
    let newPrice

    if (this.hasEditModeValue && this.editModeValue == true) {
      newPrice = this.totalPrice(this.amountInputTarget.value, this.unitPriceTarget.value)
    } else {
      newPrice = this.totalPrice(this.amountInputTarget.value, this.selectedFineTarget.selectedOptions[0].getAttribute('price'))
    }

    AutoNumeric.getAutoNumericElement(this.priceInputTarget).set(newPrice)
  }

  fillDescription() {
    this.descriptionInputTarget.value = this.selectedFineTarget.selectedOptions[0]?.getAttribute('content') || '';
  }

  totalPrice(price, amount) {
    return parseFloat(amount) * parseFloat(price)
  }

  enableSaveBtn() {
    let propertySelected = this.propertySelectorTarget.value != ''
    let titleAdded = this.titleInputTarget.value != ''
    let priceAdded = this.priceInputTarget.value != ''
    let finedAtAdded = this.finedAtCalendarTarget.value != ''

    if (propertySelected && titleAdded && priceAdded && finedAtAdded) {
      this.submitBtnTarget.disabled = false
    } else {
      this.submitBtnTarget.disabled = true
    }
  }

  displayFields() {
    if (this.chargeMethodSelectorTarget.value == '0') {
      this.feesSelectorTarget.disabled = false
      this.interestsFieldsTarget.style.display = 'none'
      this.disablePeriodSelector(false)
    } else if (this.chargeMethodSelectorTarget.value == '1') {
      this.feesSelectorTarget.disabled = true
      this.feesSelectorTarget.value = 0
      this.interestsFieldsTarget.style.display = 'none'
      this.disablePeriodSelector(false)
    } else {
      this.feesSelectorTarget.disabled = true
      this.feesSelectorTarget.value = 0
      this.interestsFieldsTarget.removeAttribute('style')
      this.disablePeriodSelector(true)
      this.resetPeriods()
    }
  }

  disablePeriodSelector(enable) {
    this.monthTarget.disabled = enable
    this.yearTarget.disabled = enable
    document.getElementById('month-selector-button').disabled = enable
    document.getElementById('year-selector-button').disabled = enable
  }

  resetPeriods() {
    this.monthTarget.disabled = false
    this.yearTarget.disabled = false
    let monthSelector = this.monthTarget.closest('.cf-selector')
    monthSelector.querySelectorAll('.cf-selector-option').forEach(option => {
      if (option.dataset.value == this.monthValue) option.click()
    })
    let yearSelector = this.yearTarget.closest('.cf-selector')
    yearSelector.querySelectorAll('.cf-selector-option').forEach(option => {
      if (option.dataset.value == this.yearValue) option.click()
    })
    this.monthTarget.value = this.monthValue
    this.yearTarget.value = this.yearValue
  }

  displayInterestCalendarField() {
    if (this.interestCheckboxTarget.checked) {
      this.interestCalendarFieldTarget.removeAttribute('style')
    } else {
      this.interestCalendarFieldTarget.style.display = 'none'
    }
    this.enableSaveBtn()
  }

  checkSaveBtn(event) {
    let currentValue = parseInt(event.currentTarget.value)
    if (currentValue > 0) {
      this.verifySaveBtn()
    } else {
      this.enableSaveBtn()
    }
  }

  verifySaveBtn() {
    let propertySelected = this.propertySelectorTarget.value != ''
    let amountAdded = this.amountInputTarget.value != ''
    if (propertySelected && amountAdded) {
      this.submitBtnTarget.disabled = false
    } else {
      this.submitBtnTarget.disabled = true
    }
  }
}
