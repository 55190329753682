import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['destroyPayments']

  destroyPayments() {
    this.destroyPaymentsTarget.value = true
  }

  reassignPayments(event) {
    this.destroyPaymentsTarget.value = false
  }
}
