import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["checkbox", "headingButton"];

  connect() {
    console.log("AccordionController connected");
  }

  toggleButtonText(event) {
    const buttonId = event.target.dataset.id;
    const button = document.getElementById(buttonId);

    if (!button) {
      return;
    }

    const hideText = button.dataset.hideText;
    const showText = button.dataset.showText;

    button.textContent = button.textContent.trim() === hideText ? showText : hideText;
  }

  toggleContinueButton() {
    const checkBox = document.getElementById("confirm-continue");
    const continueButton = document.getElementById("continue-button");

    if (!checkBox || !continueButton) {
      return;
    }

    continueButton.disabled = !checkBox.checked;
  }
}
