import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['numberInput', 'stringInput']

  connect() {
    this.localeCode = this.data.get('locale')
    this.currencyCode = this.data.get('currencyCode')
    this.countryCode = this.data.get('countryCode')
    this.formatter = new Intl.NumberFormat(this.localeCode, { style: 'currency', currency: this.currencyCode, minimumFractionDigits: 0 , maximumFractionDigits: 0 })
    this.fraction = new Intl.NumberFormat(this.localeCode, { style: 'currency', currency: this.currencyCode })

    const parts = Intl.NumberFormat(this.localeCode).formatToParts(1000.11)
    this.decimalSeparator = parts.find(part => part.type == 'decimal').value
    this.thousandSeparator = parts.find(part => part.type == 'group').value

    const fraction = Intl.NumberFormat(this.localeCode, { style: 'currency', currency: this.currencyCode }).formatToParts(1000.11)
    this.decimalPlaces = fraction.find(part => part.type == 'fraction')?.value?.length || 0

    if (this.numberInputTarget.value) {
      this.formatInitial()
    }
  }

  verifyInput(event) {
    let alreadyDecimalSeparator = this.stringInputTarget.value.includes(this.decimalSeparator)
    let allowedKey = event.shiftKey || event.ctrlKey || event.metaKey || [8, 9, 37, 39, 46].includes(event.keyCode) || event.key == this.decimalSeparator || isFinite(event.key)
    if (!allowedKey || event.key == this.thousandSeparator || (event.key == this.decimalSeparator && alreadyDecimalSeparator)) {
      event.preventDefault()
    } else {
      setTimeout(() => {
        this.formatCurrency(event)
      }, 0)
    }
  }

  formatCurrency(event) {
    if ([this.decimalSeparator, this.thousandSeparator].includes(event.key)) {
      return
    }

    const value = this.stringInputTarget.value
    const regexForCleaning = new RegExp(`[^0-9${this.decimalSeparator}]`, 'g')
    const cleanValue = value.replace(regexForCleaning, '')
    const split = cleanValue.split(this.decimalSeparator)
    const stringIntegerPart = split[0]
    const stringDecimalPart = split[1] || ''
    const numericIntegerPart = parseInt(stringIntegerPart) || 0
    const formattedIntegerPart = this.currencyString(numericIntegerPart)
    const formattedDecimalPart = stringDecimalPart.length > this.decimalPlaces
      ? stringDecimalPart.substring(0, this.decimalPlaces)
      : stringDecimalPart

    const formattedNumber = formattedDecimalPart
      ? `${formattedIntegerPart}${this.decimalSeparator}${formattedDecimalPart}`
      : formattedIntegerPart

    this.numberInputTarget.value = parseFloat(`${numericIntegerPart}.${formattedDecimalPart}`)
    this.addEventChange(this.numberInputTarget)
    this.stringInputTarget.value = formattedNumber
  }

  formatInitial() {
    let cleanValue = this.stringInputTarget.value
    let split = cleanValue.split('.')
    let integerPart = parseInt(split[0] || 0)
    let decimalPart = 0
    let numericValue = 0
    const stringDecimalPart = split[1] || ''

    if (this.decimalPlaces > 0 && split.length > 1 && parseInt(split[1]) > 0) {
      decimalPart = stringDecimalPart.length > this.decimalPlaces
        ? stringDecimalPart.substring(0, this.decimalPlaces)
        : stringDecimalPart
      
      if (decimalPart > 99 ) decimalPart = Math.floor(decimalPart / 10)
      numericValue = parseFloat(`${integerPart}.${decimalPart}`)
    } else {
      numericValue = integerPart
    }

    this.numberInputTarget.value = numericValue
    this.stringInputTarget.value = this.currencyString(numericValue, decimalPart)
  }

  currencyString(number, decimal) {
    let formattedValue = ''
    if (number % 1 == 0){
      formattedValue = this.formatter.format(number)
    } else {
      formattedValue = this.fraction.format(number)
    }

    return formattedValue.replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ')
  }

  addEventChange(element){
    const changeEvent = new Event('change', {
      bubbles: true,
      cancelable: true
    });

    element.dispatchEvent(changeEvent);
  }
}
