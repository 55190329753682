import BaseController from '../base_controller';

export default class extends BaseController {
  static targets = ['modal', 'downloadResidentType', 'downloadResidentsLink']
  static values = {
    propertyUsersXlsxPath: String,
  }

  showModal() {
    $(this.modalTarget).modal()
  }

  closeModal() {
    $(this.modalTarget).modal('hide')
  }

  changeDownloadType() {
    const params = new URLSearchParams({ query_type: this.downloadResidentTypeTarget.value })
    this.downloadResidentsLinkTarget.href = this.propertyUsersXlsxPathValue + '?' + params
  }
}
