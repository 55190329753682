import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['thCheckbox', 'rowCheckbox'];

  selectAll(event) {
    const checked = event.target.checked;
    this.rowCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }
}
