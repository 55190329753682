import BaseController from '../../base_controller'
import I18n from 'i18n-js';
import { showConfirmationDialog } from '../../../services/confirmation_dialog_service'

export default class extends BaseController {
  static targets = ['stringInput']

  initialize() {
    this.boundUpdateFields = this.updateFieldsByAccount.bind(this)
  }

  connect() {
    this.updateFieldsByAccount()
    this.setupEventListeners()
    document.addEventListener('turbo:render', this.boundUpdateFields)
  }

  disconnect() {
    document.removeEventListener('turbo:render', this.boundUpdateFields)
  }

  setupEventListeners() {
    this.element.querySelector(".account-type-select").addEventListener('change', () => {
      this.updateFieldsByAccount()
    })

    this.element.querySelectorAll('.banking-setting-value').forEach(element => {
      element.addEventListener('change', () => {
        this.bankingSettingChange = true
      })
    })

    this.element.querySelectorAll('a[disabled]').forEach(link => {
      link.addEventListener('click', (e) => {
        e.stopImmediatePropagation()
        e.preventDefault()
      })
    })
  }

  updateFieldsByAccount() {
    let emailGroup = this.element.querySelector(".email-field")
    let emailInput = emailGroup.getElementsByTagName("input")[0]

    if (this.selectValue() === "") {
      emailGroup.style.display = "none"
    } else {
      emailGroup.style.display = "block"
      emailInput.required = true
    }

    this.toggleClabeAccountFields()
    this.toggleNormalAccountFields()
  }

  toggleClabeAccountFields() {
    let inputsRequired = this.element.querySelectorAll(".clabe-account-type-input")
    let inputs = this.element.querySelectorAll(".clabe-account-type-input.required")

    if (this.selectValue() === "clabe") {
      this.getFields().clabeAccountFields.forEach(field => {
        field.style.display = "block"
      })

      inputsRequired.forEach(input => {
        input.required = true
      })
    } else {
      this.getFields().clabeAccountFields.forEach(field => {
        field.style.display = "none"
      })
      inputs.forEach(input => {
        input.required = false
        if (this.currentAccountType() !== "clabe") {
          input.value = ''
        }
      })
    }
  }

  toggleNormalAccountFields() {
    let accountTypes = ["view", "savings", "current"]
    let inputs = this.element.querySelectorAll(".normal-account-type-input")

    if (accountTypes.includes(this.selectValue())) {
      this.getFields().normalAccountFields.forEach(field => {
        field.style.display = "block"
      })
      inputs.forEach(input => {
        input.required = true
      })
    } else {
      this.getFields().normalAccountFields.forEach(field => {
        field.style.display = "none"
      })
      inputs.forEach(input => {
        input.required = false
        if (this.currentAccountType() === "clabe") {
          input.value = ''
        }
      })
    }
  }

  selectValue() {
    return this.element.querySelector(".account-type-select").value
  }

  currentAccountType() {
    return "#{@banking_setting.account_type}"
  }

  getFields() {
    return {
      normalAccountFields: this.element.querySelectorAll(".normal-account-type-field"),
      clabeAccountFields: this.element.querySelectorAll(".clabe-account-type-field")
    }
  }

  validateBankingSettingChange(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.bankingSettingChange) {
      showConfirmationDialog(this.submitForm.bind(this))
    } else {
      this.submitForm()
    }
  }

  submitForm() {
    document.querySelector('#banking-setting-submit').click()
  }

  editInfoBankingAccount() {
    this.element.querySelectorAll('.info-banking-account').forEach(input => {
      input.disabled = false
    })
  }

  onlyNumberKey(evt) {
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
      return false
    return true
  }

  onlyNumber(){
    this.stringInputTarget.value = this.stringInputTarget.value.replace(/\D/g, '');
  }

  referencedAccount(){
    let select_option = event.srcElement.value
    let referenced_account = document.getElementById('referenced_account')
    if (select_option == 'true'){
      referenced_account.classList.remove("hidden")
    }else{
      referenced_account.classList.add("hidden")
    }
  }

}
