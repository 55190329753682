import { Controller } from "stimulus"
import AutoNumeric from "autonumeric"

export default class extends Controller {
  static targets = [ 'form', 'descriptionInput', 'amountInput' ]

  initialize() {
    this.submittingForm = false;
  }

  formTargetConnected() {
    this.submittingForm = false;
  }

  async submit() {
    if (this.submittingForm) return false

    let amountValue = AutoNumeric.getAutoNumericElement(this.amountInputTarget).getNumber()

    if (this.descriptionInputTarget.value != '' && amountValue > 0) {
      this.submittingForm = true
      await this.formTarget.requestSubmit()
      this.refreshModal()
    }
  }

  enableSubmit() {
    if (this.descriptionInputTarget.value != '' && this.amountInputTarget.value != '') {
      let disabledButton = document.getElementById('salary-payment-disabled-button')
      let enabledButton = document.getElementById('salary-payment-enabled-button')

      enabledButton.classList.remove('hidden')
      disabledButton.classList.add('hidden')
    }
  }

  async refreshModal() {
    await new Promise(resolve => setTimeout(resolve, 1000));

    var selectedMonth = document.getElementById('month').value;
    var selectedYear = document.getElementById('year').value;

    fetch(`/remuneraciones/libro_liquidaciones/refresh_modal/${selectedYear}/${selectedMonth}`, {
      method: 'GET',
      headers:  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    .then(response => response.json())
    .then(data  => {
      let massiveSalaryEmployees = document.getElementById('employees-count');
      let massiveSalaryWithoutWorkedDaysIds = document.getElementById('worked-days-ids');

      massiveSalaryEmployees.textContent = data.employees;
      if (data.has_part_time_employees_without_worked_days) {
        massiveSalaryWithoutWorkedDaysIds.classList.remove('hidden');
      }
      else{
        massiveSalaryWithoutWorkedDaysIds.classList.add('hidden');
      }
    })
  }
}
