import React, { useState, useRef, useEffect } from "react";
import { Grid, Link, Button, TextField } from "@material-ui/core";
import I18n from "i18n-js";
import useStyles from "./Login.styles";
import { withRouter } from "react-router";
import EmailTextField from "../../components/EmailTextField";
import PasswordField from "../../components/PasswordField";
import { isEmailValid } from "../../utilities/actions";
import BackdropCF from "../../components/BackdropCF";
import { GLOBAL_STATE } from "../../state";
import CompanyLogo from "../../components/CompanyLogo";
import SocialSignupButtons from "../../components/BaseComponents/SocialSignupButtons";
import { useLocation } from 'react-router-dom'
import addLocale from '../../utilities/actions/addLocale';

export function sendLoginRequest(url, data, onLoad, onError) {
  const req = new XMLHttpRequest();
  const { csrfToken, ...requestBody } = data;

  req.addEventListener("load", () => {
    if (req.status >= 200 && req.status < 300) {
      onLoad(JSON.parse(req.response));
    } else {
      window.location.href = req.response.url
    }
  });
  req.addEventListener("error", onError);
  req.open("POST", url, true);
  req.setRequestHeader("Content-Type", "application/json");
  req.setRequestHeader("X-CSRF-Token", csrfToken);
  req.send(JSON.stringify(requestBody));
}

const LoginForm = (props) => {
  addLocale();
  const [currentEmailWidget, setCurrentEmailWidget] = useState("");
  const emailWidgetTextFieldRef = useRef();
  const passWidgetTextFieldRef = useRef();
  const backdropWidgetRef = useRef();
  const { error } = GLOBAL_STATE;
  const [showPasswordWidgetError, setShowPasswordWidgetError] = useState(
    error === null ? false : true
  );
  const [textPasswordWidgetError, setTextPasswordWidgetError] = useState(error);
  const redirectUrl = new URLSearchParams(useLocation().search).get('redirect_url')
  const classes = useStyles();
  const [ urlToResidents , setUrlToResidents ] = useState("") ;
  const [ residentsToken , setResidentsToken ] = useState("") ;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const utmObject = extractUtmObject(props?.location?.search);

  function onSubmitForm(event) {
    event.preventDefault();
    backdropWidgetRef.current.activate();

    let flagError = false;

    if (event.target.email.value.trim() === "") {
      emailWidgetTextFieldRef.current.validate(false, true);
      flagError = true;
    } else {
      emailWidgetTextFieldRef.current.validate(false, false);
    }

    if (flagError === false) {
      if (isEmailValid(event.target.email.value.trim())) {
        emailWidgetTextFieldRef.current.validate(false, false);
      } else {
        emailWidgetTextFieldRef.current.validate(true, false);
        flagError = true;
      }
    }

    if (
      event.target.password.value.trim() === "" &&
      event.target.email.value.trim() !== ""
    ) {
      setShowPasswordWidgetError(true);
      setTextPasswordWidgetError(
        I18n.t("modules.components.password_text_field.password_required")
      );
      flagError = true;
    } else {
      setShowPasswordWidgetError(false);
      setTextPasswordWidgetError(null);
    }

    if (flagError) {
      backdropWidgetRef.current.deactivate();
      return false;
    }

    const parseResponseUrl = (urlString) => {
      try {
        if (urlString.startsWith('/')) {
          return new URL(urlString, window.location.origin);
        }

        return new URL(urlString);
      } catch (e) {
        return '';
      }
    };

    const onLoad = (response) => {
      if (!response.authentication_successful) {
        document.querySelector('.MuiBackdrop-root').style.display = 'none';
        setShowPasswordWidgetError(true);
        setTextPasswordWidgetError(response.message);

        return;
      }

      const urlString = response.url;
      const urlObject = parseResponseUrl(urlString);
      const queryParams = new URLSearchParams(urlObject.search);
      const path = `${urlObject.origin}${urlObject.pathname}`;
      const token = queryParams.get('code');

      setUrlToResidents(path);
      setResidentsToken(token);

      if (token) {
        setShouldRedirect(true);

        return;
      }

      if (urlObject.pathname !== '/') {
        location.href = urlString;

        return;
      }
    };

    const sessionUrl = redirectUrl ? `/sessions.json?redirect_url=${redirectUrl}` : "/sessions.json";
    const form = document.getElementById("login");
    const loginRequestData = {
      email: form.querySelector('input[name="email"]').value,
      password: form.querySelector('input[name="password"]').value,
      csrfToken: form.querySelector('input[name="authenticity_token"]').getAttribute("value"),
      utm: utmObject,
    };

    sendLoginRequest(sessionUrl, loginRequestData, onLoad, () => {});
  }

  function handlechange(e) {
    setCurrentEmailWidget(e.target.value.trim());
    emailWidgetTextFieldRef.current.validate(false, false);
  }

  function extractUtmObject(searchString) {
    const searchParams = new URLSearchParams(searchString);

    const utmObject = {
      campaign: searchParams.get('utm_campaign'),
      content:  searchParams.get('utm_content'),
      term:     searchParams.get('utm_term'),
      source:   searchParams.get('utm_source'),
      medium:   searchParams.get('utm_medium')
    };

    return utmObject;
  }

  useEffect(() => {
    if (shouldRedirect) {
      document.getElementById("redirect").submit();
    }
  }, [shouldRedirect]);

  return (
    <Grid className={classes.mainContainer}>
      <BackdropCF ref={backdropWidgetRef} />
      <Grid className={classes.formContainer}>
        <Grid className={classes.MediaLogo}>
          <CompanyLogo />
        </Grid>
        <form name="redirect" id="redirect" method="post" action={urlToResidents} style={{"display": "none"}}>
          <TextField
            name="code"
            value={residentsToken}
          ></TextField>
        </form>
        <form
          name="login"
          id="login"
          className={classes.form}
          method="post"
          action={ redirectUrl ? `/sessions?redirect_url=${redirectUrl}` : "/sessions"}
          onSubmit={onSubmitForm}
        >
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <EmailTextField
            onChange={handlechange}
            ref={emailWidgetTextFieldRef}
            error={showPasswordWidgetError}
            InputLabelProps={{
              style: { backgroundColor: "white", paddingRight: "5px" },
            }}
          />
          <PasswordField
            InputLabelProps={{
              style: { backgroundColor: "white", paddingRight: "5px" },
            }}
            ref={passWidgetTextFieldRef}
            error={showPasswordWidgetError}
            helperText={textPasswordWidgetError}
          />
          <Link
            href={"/v2/password_recovery?email=" + encodeURIComponent(currentEmailWidget)}
            className={classes.recoveryPassword}
          >
            {I18n.t("modules.login.forgot_pass")}
          </Link>
          <Button className={classes.public_btn} type="submit">
            {I18n.t("modules.login.login_button")}
          </Button>
        </form>
        <SocialSignupButtons />
        <Grid item className={classes.linkSignUp}>
          <Link href="/sign_up" align="center">
            {I18n.t("modules.login.sign_up")}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(LoginForm);
