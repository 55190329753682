import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'morisityCdaBlockerAmountInput']

  activateTab(event) {
    // Remove active class from current active menu item
    const currentActive = this.menuTarget.querySelector('.menu-item.active')
    if (currentActive) {
      currentActive.classList.remove('active')
    }

    // Add active class to clicked menu item
    const menuItem = event.currentTarget.querySelector('.menu-item')
    if (menuItem) {
      menuItem.classList.add('active')
    }
  }

  // Triggered by CDA morosity_blocker select input
  toggleMorisityCdaBlockerAmountInput() {
    if (event.currentTarget.value == '1') {
      this.morisityCdaBlockerAmountInputTarget.disabled = false;
    } else {
      this.morisityCdaBlockerAmountInputTarget.disabled = true;
    }
  }
}
