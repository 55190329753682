import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    this.handleDownload()
  }

  handleDownload() {
    if (this.element.dataset.redirectUrl) {
      window.location.href = this.element.dataset.redirectUrl;
    }
  }
}
