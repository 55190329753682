import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    eventName: String,
    action: String,
    committeeMember: Boolean
  }

  trackCommitteeMemberAction() {
    trackEvent(
      this.eventNameValue,
      { additional_info: {
        action: this.actionValue,
        committee_member: this.committeeMemberValue
      }
      }
    )
  }
}
