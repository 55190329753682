import { Controller } from 'stimulus';
import I18n from 'i18n-js';

export default class extends Controller {
  static values = {
    tourEmpty: Boolean,
    newResidentsModuleEnabled: Boolean,
    showInactivePropertyUsersEnabled: Boolean,
  };
  static targets = [
    'table',
    "roleHeader",
    "inChargeHeader",
    'actionsHeader',
    "roleCell",
    "inChargeCell",
    'actionsCell',
    'optionsButton',
    'optionsDropdown',
    'disabledTab',
  ];

  connect() {
    if (this.newResidentsModuleEnabledValue && this.tourEmptyValue && this.isLargeScreen()) {
      this.showModal();
    } else {
      this.alertMessage(I18n.t('views.property_users.onboarding.new_resident_module_tour.alert_message'));  
    }
  }

  alertMessage(message) {
    const alertElement = $(".alert.error.js-triggered");
    const flashAlert = alertElement.find(".flash_alert")[0];
    const warningIcon = alertElement.find(".warning-icon")[0];
  
    flashAlert.innerHTML = message;
  
    alertElement.css({
      display: "flex",
      alignItems: "center",
      padding: "10px",
      position: "relative"
    });
  
    $(warningIcon).css({
      flexShrink: "0",
      fontSize: "24px"
    });
  
    $(flashAlert).css({
      flex: "1",
      lineHeight: "1.4",
      wordWrap: "break-word"
    });
  
    alertElement.find(".close").remove();
  
    alertElement.show();
    return true;
  }

  isLargeScreen() {
    return window.matchMedia("(min-width: 768px)").matches;
  }

  showModal() {
    const modalId = '#start_onboarding_modal';
    $(modalId).modal('show');
  }

  startTourClicked() {
    const modalId = '#start_onboarding_modal';
    $(modalId).modal('hide');

    this.startNewResidentModuleTour();
  }

  startNewResidentModuleTour() {
    const sizeKey = this.showInactivePropertyUsersEnabledValue ? 'size_with_setting' : 'size_without_setting';
    const tourId = I18n.t('views.property_users.onboarding.new_resident_module_tour.id');
    const tourSize = I18n.t(`views.property_users.onboarding.new_resident_module_tour.${sizeKey}`);
    let newResidentModuleTour = tourFactoryLearnBasic(tourId, tourSize);
    this.prepareTour(newResidentModuleTour);
    newResidentModuleTour.start();
    writeStepLearnBasicTour(newResidentModuleTour, 1);
  }

  nextStep(tour, nextPath) {
    tour.next();
    writeStepLearnBasicTour(tour, nextPath);
  }
  nextFunctionStepOne(tour) {
    this.nextStep(tour, 2);
    this.highlightColumns(this.roleHeaderTarget, this.inChargeHeaderTarget, this.roleCellTargets, this.inChargeCellTargets);
  }

  nextFunctionStepTwo(tour) {
    this.nextStep(tour, 3);
    this.clearHighlightColumns(this.roleHeaderTarget, this.inChargeHeaderTarget, this.roleCellTargets, this.inChargeCellTargets);
    this.highlightColumns(this.actionsHeaderTarget, null, this.actionsCellTargets, null);
  }

  showDropdownMenu() {
    this.optionsDropdownTarget.style.display = "block";
    this.optionsDropdownTarget.style.position = 'absolute';
  }

  addStyleOptionsButton() {
    this.optionsButtonTarget.classList.add('shepherd-target', 'shepherd-enabled');
    this.optionsButtonTarget.style.boxShadow = '0 0 0px 0px rgba(0, 0, 0, 0)';
    this.optionsButtonTarget.style.zIndex = 10000;
  }

  removeStyleOptionsButton() {
    this.optionsButtonTarget.classList.remove('shepherd-target', 'shepherd-enabled');
    this.clearStyleElement(this.optionsButtonTarget);
  }
      
  nextFunctionStepThree(tour) {
    this.showDropdownMenu();
    this.nextStep(tour, 4);
    this.clearHighlightColumns(this.actionsHeaderTarget, null, this.actionsCellTargets, null);
    this.addStyleOptionsButton();
  }

  previousFunctionStepThree(tour) {
    writeStepLearnBasicTour(tour, 2);
    this.highlightColumns(this.roleHeaderTarget, this.inChargeHeaderTarget, this.roleCellTargets, this.inChargeCellTargets);
    this.clearHighlightColumns(this.actionsHeaderTarget, null, this.actionsCellTargets, null);
    tour.back()
  }

  nextFunctionStepFour(tour) {
    this.optionsDropdownTarget.style.display = '';
    if (this.showInactivePropertyUsersEnabledValue) {
      this.nextStep(tour, 5);
      this.removeStyleOptionsButton();
    }
    else {
      this.endTour(tour)
    }
  }

  previousFunctionStepFour(tour) {
    writeStepLearnBasicTour(tour, 3);
    this.highlightColumns(this.actionsHeaderTarget, null, this.actionsCellTargets, null);
    this.optionsDropdownTarget.style.display = '';
    this.removeStyleOptionsButton();
    tour.back()
  }

  endTour(tour) {
    tour.complete();
    endBasicTour(tour.options.id)
  }

  previousFunctionStepFive(tour) {
    writeStepLearnBasicTour(tour, 4);
    this.showDropdownMenu();
    this.addStyleOptionsButton();
    tour.back()
  }

  highlightColumns(leftHeader, rightHeader, leftCells, rightCells) {
    if (leftHeader) {
      leftHeader.classList.add('highlight-column-left');
    }
    if (rightHeader) {
      rightHeader.classList.add('shepherd-target', 'shepherd-enabled');
      rightHeader.style.boxShadow = '0 0 0px 0px rgba(0, 0, 0, 0)';
      rightHeader.style.zIndex = 9999;
      rightHeader.style.backgroundColor = 'white';
    }
    if (leftCells) {
      this.setStyleCells(leftCells, true);
    }
    if (rightCells) {
      this.setStyleCells(rightCells);
    }
  }

  setStyleCells(cells, isLeft = false) {
    cells.forEach(cell => {
      if (isLeft) {cell.classList.add('highlight-column-left')} 
      cell.classList.add('shepherd-target', 'shepherd-enabled');
      cell.style.position = 'relative';
      cell.style.boxShadow = '0 0 0px 0px rgba(0, 0, 0, 0)';
      cell.style.zIndex = 9999;
      cell.style.backgroundColor = 'white';
    });
  }

  clearHighlightColumns(leftHeader, rightHeader, leftCells, rightCells) {
    if(leftHeader) {
      this.clearStyleHeader(leftHeader, true);
    }
    if (rightHeader) {
      this.clearStyleHeader(rightHeader);
    }
    if(leftCells){
      this.clearStyleCells(leftCells, true);
    }
    if(rightCells){
      this.clearStyleCells(rightCells);
    }
  }

  clearStyleHeader(header, isLeft = false) {
    if (isLeft) {
      header.classList.remove('highlight-column-left');
    } else {
      header.classList.remove('shepherd-target', 'shepherd-enabled');
    }
    this.clearStyleElement(header);
  }
  
  clearStyleCells(cells, isLeft = false) {
    cells.forEach(cell => {
      if (isLeft) {
        cell.classList.remove('highlight-column-left');
      }
      cell.classList.remove('shepherd-target', 'shepherd-enabled');
      this.clearStyleElement(cell);
    });
  }

  clearStyleElement(element) {
    element.style.position = '';
    element.style.boxShadow = '';
    element.style.zIndex = '';
    element.style.backgroundColor = '';
  } 

  prepareTour(tour) {
    addSteptoLearnBasicTour({
      tour: tour,
      attachTo: { element: this.tableTarget, on: 'top' },
      text: `
        <p class="tour-title center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_one.tittle')}</p>
        <p class="center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_one.content')}</p>
      `,
      nextPath: window.location.pathname,
      nextText: I18n.t('common.next'),
      id: 1,
      finalAction: false,
      nextFunction: (tour) => this.nextFunctionStepOne(tour),
    });
    
    addSteptoLearnBasicTour({
      tour: tour,
      attachTo: { element: this.roleHeaderTarget, on: 'left' },
      text: `
        <p class="tour-title center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_two.tittle')}</p>
        <p class="center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_two.content')}</p>
      `,
      nextPath: window.location.pathname,
      previousPath: window.location.pathname,
      nextText: I18n.t('common.next'),
      previousText: I18n.t('common.back'),
      id: 2,
      finalAction: false,
      nextFunction: (tour) => this.nextFunctionStepTwo(tour),
    });

    addSteptoLearnBasicTour({
      tour: tour,
      attachTo: { element: this.actionsHeaderTarget, on: 'left-start' },
      text: `
        <p class="tour-title center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_three.tittle')}</p>
        <p class="center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_three.content')}</p>
      `,
      nextPath: window.location.pathname,
      previousPath: window.location.pathname,
      nextText: I18n.t('common.next'),
      previousText: I18n.t('common.back'),
      id: 3,
      finalAction: false,
      nextFunction: (tour) => this.nextFunctionStepThree(tour),
      previousFunction: (tour) => this.previousFunctionStepThree(tour),
    });

    addSteptoLearnBasicTour({
      tour: tour,
      attachTo: { element: this.optionsDropdownTarget, on: 'left' },
      text: `
        <p class="tour-title center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_four.tittle')}</p>
        <p class="center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_four.content')}</p>
      `,
      nextPath: window.location.pathname,
      previousPath: window.location.pathname,
      nextText: this.showInactivePropertyUsersEnabledValue ? I18n.t('common.next') : I18n.t('common.finish'),
      previousText: I18n.t('common.back'),
      id: 4,
      finalAction: false,
      nextFunction: (tour) => this.nextFunctionStepFour(tour),
      previousFunction: (tour) => this.previousFunctionStepFour(tour),
    });

    addSteptoLearnBasicTour({
      tour: tour,
      attachTo: { element: document.querySelector('[data-property-users--onboarding-target="disabledTab"]'), on: 'bottom' },
      text: `
        <p class="tour-title center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_five.tittle')}</p>
        <p class="center-text">${I18n.t('views.property_users.onboarding.new_resident_module_tour.step_five.content')}</p>
      `,
      nextPath: window.location.pathname,
      previousPath: window.location.pathname,
      nextText: I18n.t('common.finish'),
      previousText: I18n.t('common.back'),
      id: 5,
      finalAction: false,
      nextFunction: (tour) => this.endTour(tour),
      previousFunction: (tour) => this.previousFunctionStepFive(tour),
    });
  }
}
