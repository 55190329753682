import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['front', 'back'];

  changeBack(event) {
    this._changeFile(event, this.backTarget);
  }

  changeFront(event) {
    this._changeFile(event, this.frontTarget);
  }

  removeBack(event) {
    event.preventDefault();
    this._removeFile(this.backTarget);
  }

  removeFront(event) {
    event.preventDefault();
    this._removeFile(this.frontTarget);
  }

  confirmRemoval() {
    this.targetToRemove.querySelector('.form-control').innerHTML = null;
    this.targetToRemove.querySelector('input[type=file]').files = null;
    this.targetToRemove.querySelector('input[type=hidden]').value = true;
    this.targetToRemove.querySelector('.remove-file').classList.add('hidden');
    this.targetToRemove = null;
  }

  _changeFile({ target }, element) {
    let file = target.files[0];
    // Only GIF, JPEG or PNG images, and no larger than 5MB
    if (['image/gif', 'image/jpeg', 'image/png'].includes(file.type) && file.size <= 5 * 1024 * 1024) {
      element.querySelector('.form-control').innerHTML = file.name;
      element.querySelector('.remove-file').classList.remove('hidden');
      element.querySelector('input[type=hidden]').value = null;
    } else {
      target.files = null;
    }
  }

  // admin view of property users module
  adminSelectedIdentityDocuments({ params, target }) {
    let files = target.files;
    let validFiles = [];
    let showError = false;

    if (files.length == 1 || files.length == 2) {
      files = [files[0], files[1]].filter(file => file)

      files.forEach(function(file) {
        validFiles.push(['image/gif', 'image/jpeg', 'image/png'].includes(file.type) && file.size <= 5 * 1024 * 1024)
      })

      let everyFileIsValid = [...new Set(validFiles)].toString();

      if (everyFileIsValid == 'true') {
        let propertyUserContainer = document.querySelector('.property_options_container.property_options_' + params.row);
        const submitBtn = propertyUserContainer.querySelector(".upload-identity-document-submit-btn");
        const spanElement = submitBtn.querySelector('span');
        spanElement && (spanElement.textContent = params.uploadingText);
        submitBtn.querySelector('i')?.classList.remove('fa-upload');
        submitBtn.querySelector('i')?.classList.add('fa-spinner', 'fa-spin');
        submitBtn.classList.add('disabled');
        submitBtn.click();
      } else {
        showError = true;
      }
    } else {
      showError = true;
    }

    if (showError) {
      alert('El documento debe ser una o dos imágenes y no deben pesar más de 5 MB');
      target.value = null;
    }
  }

  adminUploadIdentityDocuments(event) {
    const inputElement = event.target.querySelector("input[name='user_documents[identity_documents][]']");
    if (inputElement.files.length == 0) {
      event.stopPropagation();
      event.preventDefault();
      inputElement.click();
    }
  }

  _removeFile(element) {
    this.targetToRemove = element;
    $('#removeIdentityCard').modal('show');
  }
};
