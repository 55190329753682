import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['submitButton', 'cashFlow', 'month', 'year']

  validateSubmitButton(event) {
    let checkboxes = document.querySelectorAll('.center-rows input[type=checkbox]')

    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        this.submitButtonTarget.disabled = false;
        return true;
      } else {
        this.submitButtonTarget.disabled = true;
      }
    };
  }

  async validatePeriodExpense() {
    let data = await this.getPeriodExpense()
    let cashFlow = document.getElementById('bank_reconciliation_checkbox')

    if (data.bank_reconciliation_closed) {
      cashFlow.disabled = false
    } else {
      cashFlow.disabled = true
      cashFlow.checked = false
    }

  }

  async getPeriodExpense() {
    let data = { month: this.monthTarget.value, year: this.yearTarget.value }
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    const data_response = await fetch('/reports/no_period/period_expense',
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      })

    return await data_response.json()
  }
}
