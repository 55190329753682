import { Controller } from "@hotwired/stimulus";
import I18n from "i18n-js";

export default class extends Controller {
  redirect(event) {
    event.preventDefault();
    const url = this.element.dataset.redirectUrl;
    const mixpanelDescription = this.element.dataset.mixpanelDescription;

    trackEvent(
      'NewFeatures',
      {
        action: mixpanelDescription || I18n.t('mixpanel.advertisement.new_features.unknown_button')
      }
    ).then(() => {
      if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    });
  }
}
