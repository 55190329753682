import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['paymentType', 'paymentNumber', 'checkbook']

  connect() {
    const checkbookCondition = this.paymentTypeTarget.dataset.checkbookCondition === 'true';
    this.paymentTypeTarget.addEventListener('change', () => {
      if (this.paymentTypeTarget.value == 0 && checkbookCondition) {
        this.showCheckbook()
      } else {
        this.showPaymentNumber()
      }
    })
  }

  showCheckbook() {
    this.paymentNumberTarget.setAttribute('style', 'display: none;')
    if (this.hasCheckbookTarget) {
      this.checkbookTarget.removeAttribute('style')
    }
  }

  showPaymentNumber() {
    this.paymentNumberTarget.removeAttribute('style')
    if (this.hasCheckbookTarget) {
      this.checkbookTarget.setAttribute('style', 'display: none;')
    }
  }
}
