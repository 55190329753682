import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let tabBox = document.getElementById('tab-box');
    let tabsRow = tabBox.getElementsByClassName('tabs');
    let tabElements = tabsRow[0].getElementsByClassName('tab');
    let sum = 0;
    for (let tab of tabElements) {
      sum += tab.clientWidth;
    }
    var scrollDuration = 300;
    let scrollLeft = this.getActiveTabPosition(tabElements, tabBox);

    (function() {
      $(tabsRow).animate({
        scrollLeft: scrollLeft,
      }, scrollDuration);
    })();

    if (this.element.dataset.isFromResidents === "true") {
      this.checkActiveTab();
    }
  }

  getActiveTabPosition(tabs, tabBox) {
    const chevronMargin = 28;
    const padding = 4;
    let sum = 0;
    let i = 0;
    while (i < tabs.length && !tabs[i].classList.contains('active')) {
      sum += tabs[i].clientWidth;
      i += 1;
    }
    return Math.max(...[sum + tabs[i].clientWidth / 2 - tabBox.clientWidth / 2 + chevronMargin + padding * (i + 1), 0]);
  }

  checkActiveTab() {
    const activeTab = document.querySelector('a.active[data-is-resident="true"]');

    const nameInput = document.getElementById('name_resident');
    const propertyNameInput = document.getElementById('property_name_resident');
    const roleInput = document.getElementById('role_resident');
    const submitButton = document.getElementById('submit_button_resident');
  
    if (nameInput && propertyNameInput && roleInput && submitButton) {
      const shouldDisable = activeTab && activeTab.getAttribute('data-index') === 'grouped_bills';
      this.toggleInputState(nameInput, shouldDisable);
      this.toggleInputState(propertyNameInput, shouldDisable);
      this.toggleInputState(roleInput, shouldDisable);
      this.toggleInputState(submitButton, shouldDisable);
    }
  }
  
  toggleInputState(element, disable) {
    if (element) {
      element.disabled = disable;
    }
  }
}
