import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'disabledButton' ]

  call(event){
    event.currentTarget.disabled = true
    event.currentTarget.classList.add('disabled')
  }

  replaceWithDisableButton(event){
    event.currentTarget.classList.add('hidden')
    this.disabledButtonTarget.classList.remove('hidden')
  }

  disableLinkElement(event){
    const link = event.currentTarget
    if (link.dataset.disabled) {
        event.preventDefault()
        event.stopImmediatePropagation()
        return
    }
    link.dataset.disabled = true
    this.disabledButtonTarget.disabled = true
    this.disabledButtonTarget.classList.add('disabled')
  }
}
