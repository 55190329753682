import I18n from "i18n-js";

const getCSRFToken = () => {
  const tokenElement = document.querySelector('meta[name="csrf-token"]');
  return tokenElement ? tokenElement.getAttribute("content") : null;
};

const handleFetchErrors = async (response, errorMessage) => {
  if (!response.ok) {
    const errorDetails = await response.text();
    throw new Error(`${errorMessage}: ${errorDetails}`);
  }
};

const HTTP_METHODS = ["GET", "POST", "PUT", "DELETE", "PATCH"];

const fetchJsonWithCSRF = HTTP_METHODS.reduce((acc, method) => {
  acc[method.toLowerCase()] = async (
    url,
    {
      headers: customHeaders = {},
      errorMessage = I18n.t("common.error"),
      ...restOptions
    } = {}
  ) => {
    const headers = {
      "X-CSRF-Token": getCSRFToken(),
      "Content-Type": "application/json",
      Accept: "application/json",
      ...customHeaders,
    };

    try {
      const response = await fetch(url, { ...restOptions, headers, method });
      await handleFetchErrors(response, errorMessage);
      return await response.json();
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  };
  return acc;
}, {});

export default fetchJsonWithCSRF;
