import { Controller } from 'stimulus'

export default class extends Controller {
  paymentAssignmentAmount(event) {
    const element = event.currentTarget;
    const propertyId = element.getAttribute('target').split('.prop')[1];
    const debtId = element.id.split('debts_amount_')[1];
    const keyPropertyPrice = `property_id_${propertyId}_price`;
    const keyPropertyPartialAmount = `partial_amount_${debtId}`;
    const keyPropertyDebtsAmount = `property_id_${propertyId}_debts_amount`;

    const maxAmount = parseFloat(element.className.split('max_amount_')[1]);
    let partialAmount = parseFloat(AutoNumeric.getAutoNumericElement(element).get());

    if (partialAmount > maxAmount) partialAmount = maxAmount;
    if (isNaN(partialAmount)) partialAmount = 0;

    const currentMaxAmount = localStorage.getItem(keyPropertyPartialAmount) !== null
        ? parseFloat(localStorage.getItem(keyPropertyPartialAmount))
        : parseFloat(element.className.split('max_amount_')[1]);

    if (
        localStorage.getItem(keyPropertyPartialAmount) === null ||
        partialAmount !== parseFloat(localStorage.getItem(keyPropertyPartialAmount))
    ) {
        localStorage.setItem(keyPropertyPartialAmount, 0);
    }

    let price = parseFloat(localStorage.getItem(keyPropertyPrice)) || 0;

    let priceId = ''
    if (document.querySelector(element.getAttribute('target'))){
      priceId = element.getAttribute('target')
    } else {
      priceId = '#price_new_payment'
    }

    const priceElement = document.querySelector(priceId);
    if (priceElement) {
        priceElement.disabled = true;
    }

    if (parseFloat(localStorage.getItem(keyPropertyPartialAmount)) === 0) {
        localStorage.setItem(keyPropertyPartialAmount, partialAmount);
        price -= currentMaxAmount;
        price += partialAmount;
        localStorage.setItem(keyPropertyPrice, price);

        if (priceElement) {
            AutoNumeric.getAutoNumericElement(priceElement).set(price);
            const siblingInput = priceElement.parentElement.querySelector('#payment_price_val');
            if (siblingInput) {
                siblingInput.value = price;
            }
        }
    }

    element.value = partialAmount;
    AutoNumeric.getAutoNumericElement(element).set(partialAmount);
    const debtsAmount = JSON.parse(localStorage.getItem(keyPropertyDebtsAmount));

    if (debtsAmount) {
        if (debtsAmount.hasOwnProperty(debtId)) {
            debtsAmount[debtId] = partialAmount;
            const debtsAmountString = JSON.stringify(debtsAmount);

            const selectedDebtsAmountInput = document.querySelector(`#selected_debts_amount_${propertyId}`);
            if (selectedDebtsAmountInput) {
                selectedDebtsAmountInput.value = debtsAmountString;
            }
            localStorage.setItem(keyPropertyDebtsAmount, debtsAmountString);
        }
    }

    const refreshEvent = new Event('refresh_autonumeric');
    document.dispatchEvent(refreshEvent);
  }
}
