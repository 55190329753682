import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["warnings", "employees"];

  connect() {
    this.checkConditions();
  }

  checkConditions() {
    const warningsEmpty = this.warningsTarget.dataset.empty === "true";
    const employeesEmpty = this.employeesTarget.dataset.empty === "true";

    if (warningsEmpty && employeesEmpty) {
      const checkBox = document.getElementById("confirm-continue");
      const continueButton = document.getElementById("continue-button");

      if (checkBox && continueButton) {
        checkBox.checked = true;
        continueButton.disabled = false;
      }
    }
  }
}
