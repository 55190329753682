import I18n from "i18n-js";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["switch"];

  async toggleInCharge({ currentTarget }) {
    const propertyId = currentTarget.dataset.propertyId;

    this._toggleSwitchesDisabled({ propertyId, isDisabled: true });

    try {
      const url = currentTarget.dataset.url;

      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
      });
      if (!res.ok) throw new Error(I18n.t("common.error"));

      const { propertyUserInChargeId, response } = await res.json();

      if (!response) throw new Error(I18n.t("common.error"));

      this._updateSwitchTargets({ propertyId, propertyUserInChargeId });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      this._toggleSwitchesDisabled({ propertyId, isDisabled: false });
    }
  }

  _updateSwitchTargets({ propertyId, propertyUserInChargeId }) {
    this._forEachSwitchTarget(propertyId, (el) => {
      const isInCharge =
        Number(el.dataset.propertyUserId) === Number(propertyUserInChargeId);
      el.classList.toggle("in_charge", isInCharge);
      el.dataset.inCharge = isInCharge;
      el.nextElementSibling.textContent = I18n.t(isInCharge ? "yes" : "no");
    });
  }

  _toggleSwitchesDisabled({ propertyId, isDisabled }) {
    this._forEachSwitchTarget(propertyId, (el) => {
      el.classList.toggle("disabled", isDisabled);
    });
  }

  _forEachSwitchTarget(propertyId, callback) {
    this.switchTargets.forEach((el) => {
      if (Number(el.dataset.propertyId) === Number(propertyId)) {
        callback(el);
      }
    });
  }
}
