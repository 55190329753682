import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["reserveBtn", "errorMsg", "formInput"];

  connect() {
    this.reserveBtnTarget.addEventListener(
      "click",
      this.validateSelection.bind(this)
    );

    this.formInputTarget.addEventListener("change", this.hideError.bind(this));
  }

  validateSelection(event) {
    if (!this.formInputTarget.value.trim()) {
      event.preventDefault();
      this.showError();
    }
  }

  showError() {
    this.errorMsgTarget.style.display = "block";
  }

  hideError() {
    this.errorMsgTarget.style.display = "none";
  }
}
