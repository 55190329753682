import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'submitButton']

  open(event) {
    let currentTarget = event.currentTarget
    $(this.modalTarget).modal()
    this.submitButtonTarget.setAttribute('action', currentTarget.dataset.actionUrl)
  }

  close() {
    $(this.modalTarget).modal('hide')
  }
}
