import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["searchInput", "unitRow"];
  static values = { viewLimit: Number };

  connect() {
    this.checkSearchInputVisibility();
  }

  checkSearchInputVisibility() {
    const unitCount = this.unitRowTargets.length;
    const shouldShowSearchInput = unitCount > this.viewLimitValue;

    this.searchInputTarget.classList.toggle("hidden", !shouldShowSearchInput);
  }

  filterUnits() {
    const searchTerm = this.searchInputTarget.value.trim().toLowerCase();

    this.unitRowTargets.forEach((unitRow) => {
      const unitText = unitRow.textContent.trim().toLowerCase();
      const shouldShowUnit = unitText.includes(searchTerm);

      unitRow.classList.toggle("hidden", !shouldShowUnit);
    });
  }
}
