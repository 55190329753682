import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radioButton"];

  connect() {
    this.radioButtons = this.element.querySelectorAll(".redirect-radio");

    this.radioButtons.forEach((radio) => {
      radio.addEventListener("change", () => {
        if (radio.checked) {
          const redirectUrl = radio.dataset.url;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        }
      });
    });
  }
}
